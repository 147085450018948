import { getTranslation } from '../language-helper';
export function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
export function newSmallGuid() {
    return 'xxxxxxxx-xxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export function getActiveStates() {

    let data = [
        {
            id: true,
            value: getTranslation("active")
        },
        {
            id: false,
            value: getTranslation("deactive")
        }
    ];
    return data;
}
export function getBooleanStates() {

    let data = [
        {
            id: true,
            value: getTranslation("yes")
        },
        {
            id: false,
            value: getTranslation("no")
        }
    ];
    return data;
}
export function getEmployeeState() {

    let data = [
        {
            id: true,
            value: getTranslation("employer")
        },
        {
            id: false,
            value: getTranslation("unemployment")
        }
    ];
    return data;
}
export default { random, newGuid, newSmallGuid, getActiveStates, getEmployeeState, getBooleanStates, b64toBlob }